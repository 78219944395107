/** @jsx jsx */
import { jsx } from "theme-ui";

// import { StringParam, useQueryParam } from "use-query-params";
import DLWLayout from "../../layouts/default";
import SearchAbhyasis from "../../components/SearchAbhyasis";

function SearchAbhyasisPage() {
  // const [name] = useQueryParam("name", StringParam);

  return (
    <DLWLayout>
      <SearchAbhyasis isTrainerSection />
    </DLWLayout>
  );
}

export default SearchAbhyasisPage;
