/** @jsx jsx */
import { jsx, Flex, Spinner, Container, Alert } from "theme-ui";
import RenderTableData from "sites-common/components/RenderTableData";
import {
  DefaultButton,
  IconButton,
  Stack,
  StackItem,
} from "office-ui-fabric-react";
import { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { DynamicField as DF } from "sites-common/components/DynamicField";
import { useSrcmApi } from "../../fetch-srcm-api";

const min = (a, b) => (a < b ? a : b);

const SrcmSearchPage = ({
  defaultKey,
  defaultValue,
  reduxKey,
  apiParams,
  keyOptions,
  showHeader,
  colDefns,
  noSearchDisplay,
  defaultPageSize,
}) => {
  const [fkey, setFkey] = useState(defaultKey);
  const [fvalue, setFvalue] = useState(defaultValue);

  const [page, setPage] = useState(1);
  const [pageSize] = useState(defaultPageSize);

  const params = useMemo(
    () => ({
      ...apiParams,
      methodParams: {
        ...apiParams.methodParams,
        [fkey]: fvalue,
        page,
        page_size: pageSize,
      },
    }),
    [apiParams, fkey, fvalue, page, pageSize]
  );

  const { isFetching, data, errorMessage, updateParams } = useSrcmApi(
    reduxKey,
    params
  );

  const doSearch = useCallback(() => {
    setPage(1);
    updateParams({
      ...params,
      methodParams: { ...params.methodParams, page: 1 },
    });
  }, [params, updateParams]);

  // call updateParams for refreshing only on page change
  // useEffect(() => {
  //   updateParams(params);
  // }, [page]);

  const incrPage = useCallback(() => {
    updateParams({
      ...params,
      methodParams: { ...params.methodParams, page: page + 1 },
    });
    setPage(page + 1);
  }, [page, params, setPage, updateParams]);

  const dc = data?.count;
  const lastPage = useCallback(() => {
    const l = Math.floor(dc / pageSize) + 1;

    updateParams({
      ...params,
      methodParams: { ...params.methodParams, page: l },
    });
    setPage(l);
  }, [params, setPage, updateParams, dc, pageSize]);

  const decrPage = useCallback(() => {
    updateParams({
      ...params,
      methodParams: { ...params.methodParams, page: page - 1 },
    });
    setPage(page - 1);
  }, [page, params, setPage, updateParams]);

  const valid = !isFetching && !errorMessage;

  return (
    <div>
      <Container sx={{ m: 2 }}>
        {defaultKey !== "" && !noSearchDisplay && (
          <Stack horizontal wrap sx={{ bg: "secondary", p: 3 }}>
            <StackItem>
              {keyOptions.length > 1 && (
                <DF
                  sx={{ minWidth: 120 }}
                  name="fkey"
                  label=""
                  type="select"
                  value={fkey}
                  onChange={setFkey}
                  options={keyOptions}
                  props={{ styles: { root: { minWidth: 100 } } }}
                />
              )}
            </StackItem>
            <StackItem>
              <DF
                sx={{ minWidth: 120 }}
                name="fvalue"
                value={fvalue}
                onChange={setFvalue}
              />
            </StackItem>
            <StackItem>
              <DefaultButton
                text="Search"
                onClick={doSearch}
                iconProps={{ iconName: "Search" }}
                disabled={fvalue.trim() === ""}
              />
            </StackItem>
          </Stack>
        )}

        {isFetching && <Spinner />}
        {!isFetching && errorMessage && <Alert>{errorMessage}</Alert>}

        {valid && data && !("results" in data) && (
          <Alert>{JSON.stringify(data)}</Alert>
        )}

        {valid &&
          data &&
          "results" in data &&
          (data.results.length === 0 ? (
            <Alert>No Records Found</Alert>
          ) : (
            <div>
              <Flex sx={{ alignItems: "center", opacity: 0.75 }}>
                {data.previous && (
                  <IconButton
                    title="First Page"
                    iconProps={{ iconName: "ChevRonLeftEnd6" }}
                    text=""
                    onClick={doSearch}
                  />
                )}
                {data.previous && (
                  <IconButton
                    title="Previous Page"
                    iconProps={{ iconName: "ChevronLeftSmall" }}
                    text=""
                    onClick={decrPage}
                  />
                )}
                <span>
                  Showing {min((page - 1) * pageSize + 1, data.count)}-
                  {min(page * pageSize, data.count)} of {data.count} records{" "}
                </span>
                {data.next && (
                  <IconButton
                    title="Next Page"
                    iconProps={{ iconName: "ChevronRightSmall" }}
                    text=""
                    onClick={incrPage}
                  />
                )}
                {data.next && (
                  <IconButton
                    title="Last Page"
                    iconProps={{ iconName: "ChevRonRightEnd6" }}
                    text=""
                    onClick={lastPage}
                  />
                )}
              </Flex>

              <RenderTableData
                showHeader={showHeader}
                colDefns={colDefns}
                rowData={data.results}
              />
            </div>
          ))}
      </Container>
    </div>
  );
};

SrcmSearchPage.propTypes = {
  reduxKey: PropTypes.string.isRequired,
  apiParams: PropTypes.shape({
    api: PropTypes.string.isRequired,
    methodParams: PropTypes.shape({}),
    client: PropTypes.string,
  }).isRequired,
  defaultKey: PropTypes.string.isRequired,
  keyOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  colDefns: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func]))
  ),
  defaultValue: PropTypes.string,
  noSearchDisplay: PropTypes.bool,
  defaultPageSize: PropTypes.number,
  showHeader: PropTypes.bool,
};

SrcmSearchPage.defaultProps = {
  // reduxKey: "search-key",
  // apiParams: { api: `/api/v2/cities/` },
  // defaultKey: "name__istartswith",
  // defaultValue: "x",
  // keyOptions: [
  //   { name: "name__istartswith", label: "Name Starts With" },
  //   { name: "name__icontains", label: "Name Contains" },
  // ],
  defaultValue: "",
  colDefns: [
    ["ID", "id"],
    ["Name", "name"],
  ],
  noSearchDisplay: false,
  defaultPageSize: 20,
  showHeader: true,
};

export default SrcmSearchPage;
