/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import PropTypes from "prop-types";
import { doFormatShortDate } from "@heartfulnessinstitute/react-hfn-forms/dist/formatting";
import SrcmSearchPage from "gatsby-plugin-hfn-profile/components/SrcmSearchPage";
import AbhyasiEntity from "gatsby-plugin-hfn-profile/components/SrcmEntities/AbhyasiEntity";
import useAuth from "gatsby-plugin-hfn-profile/auth/useAuth";
import { abhyasStage } from "sites-common/utils/validations";

const defaultColDefns = [
  ["", (r) => <AbhyasiEntity abhyasiId={r.ref} display=" " item={r} />],
  ["Name", "name"],
  ["Hearfulness Id", "ref"],
  ["Abhyas Stage", (r) => abhyasStage(r.record_type, r.ref)],
  [
    "Started Practice",
    (r) => (r.date_of_joining ? doFormatShortDate(r.date_of_joining) : ""),
  ],
  ["Gender", "gender"],
  ["Age", "age"],
  ["City", (r) => (typeof r?.city === "object" ? r?.city?.name : r.city)],
  ["Email", "email"],
  ["Mobile", "mobile"],
  ["Phone", "phone"],
];

const keyOptions = [
  { name: "ref", label: "Heartfulness Id" },
  { name: "name", label: "First Name or Last Name or Full Name" },
  // { name: "city__iexact", label: "City" },
  { name: "email", label: "Email" },
  { name: "mobile", label: "Mobile" },
];

const SearchAbhyasis = ({
  filters,
  title,
  reduxKey,
  colDefns,
  defaultQuery,
  defaultPageSize,
  isTrainerSection,
}) => {
  const { srcmProfile } = useAuth();
  if (
    filters &&
    ((Array.isArray(filters) && filters.length < 1) || !Array.isArray(filters))
  ) {
    return "ERROR: filters cannot be null";
  }
  const filtersRef = filters.includes("ref");

  let defaultKey = filtersRef ? "ref" : filters[0];
  let defaultValue = filtersRef ? srcmProfile?.abhyasiId : " ";
  try {
    if (Array.isArray(defaultQuery) && defaultQuery.length === 2) {
      [defaultKey, defaultValue] = defaultQuery;
    }
  } catch (e) {
    //
  }

  return (
    <div sx={{ fontSize: "0.8rem" }}>
      <Text variant="title">{title}</Text>
      <SrcmSearchPage
        reduxKey={reduxKey}
        apiParams={{
          api: isTrainerSection
            ? `/api/v2/abhyasis/search/`
            : `/api/v2/abhyasis/`,
          client: "profileClient",
        }}
        defaultKey={defaultKey}
        defaultValue={defaultValue}
        keyOptions={keyOptions.filter((r) => filters.includes(r.name))}
        colDefns={colDefns}
        defaultPageSize={defaultPageSize}
      />
    </div>
  );
};

SearchAbhyasis.propTypes = {
  title: PropTypes.string,
  filters: PropTypes.arrayOf(PropTypes.string),
  reduxKey: PropTypes.string,
  colDefns: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOf(PropTypes.string, PropTypes.func))
  ),
  defaultPageSize: PropTypes.number,
  defaultQuery: PropTypes.arrayOf(PropTypes.string),
  isTrainerSection: PropTypes.bool,
};
SearchAbhyasis.defaultProps = {
  title: "Search Heartfulness Practitioners",
  filters: keyOptions.map((r) => r.name),
  reduxKey: "search-practitioners",
  colDefns: defaultColDefns,
  defaultPageSize: 20,
  defaultQuery: null,
  isTrainerSection: false,
};

export default SearchAbhyasis;
